
.Background{ 
    top: 0;
    box-shadow: 0px 10px 40px #000020;
    background-color: #000000;
    height: 10%;
    width: 100%;
    z-index: 10;
    overflow: hidden;
    
}

.Logo{
    position: relative;
    float: left;
   height: 50px;
  top:10px;
  left:20px;
} 

#logo-mini{
  height:40px;
  position: absolute;
left:10px;
top:5px;
  
  
}
.nav-item{
   margin-right: 30px;
   float:left; 
   margin-top: 10px; 
   font-size: 20px;
   list-style-type:none;
   margin-left:5px;
   padding-bottom: 10px;
}
.nav-link{
        color: white;
        text-decoration: none;
}
.logIn{
  float:right;
  margin-top:30px;
  margin-right:20px;
  color: #fff !important;

}


.btn-secondary{
  background-color: #011118 !important;
 }

.list{
margin-left: 7%;
  
}
.nav-item-login{
  float:right;
}
.Burger-icon{
  background:transparent;
  color:#fff;
  border:none;
  position: absolute;
  right:5px;
  outline: none;
}

#Background-mini{
height: 50px;
}