.Cname {
    z-index: 100;
    display: flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 70px;
    justify-content: center;
    align-items: center;
    background-color: #0066b3 !important;
    height: 100px;
    width: 300px;
    opacity: 0.7;
  }
  .Cname h1 {
    color: white;
    opacity: 1 !important;
  }
  .content-img {
    z-index: 0;
    top: 70px;
    width: 500%;
    height: 100% !important;
    margin: 0;
    left: 0;
    position: absolute !important;
    animation: slider 20s infinite;
  }
  @keyframes slider {
    0% {
      left: 0;
    }
    20% {
      left: 0;
    }
    25% {
      left: -100%;
    }
    45% {
      left: -100%;
    }
    50% {
      left: -200%;
    }
    70% {
      left: -200%;
    }
    75% {
      left: -300%;
    }
    95% {
      left: -300%;
    }
    100% {
      left: -400%;
    }
  }
  .content-img img {
    width: 20%;
    height: auto;
    float: left;
  }
  
  .Hwrapper {
    overflow: hidden;
    position: absolute !important;
    width: 100%;
    height: 550px !important;
  }
  .container {
    flex-wrap: wrap !important;
    display: flex;
    padding-top: 500px;
    width: 100%;
    z-index: 1001;
  }
  .main-content {
    flex: 2;
    justify-content: space-around !important;
    flex-direction: row;
    min-width: 500px;
    margin: 5px;
    background-color: #fff;
    z-index: 1001;
    background: linear-gradient(
      0deg,
      rgba(236, 236, 236, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .side-content {
    z-index: 1001;
    min-width: 200px;
    background: linear-gradient(
      0deg,
      rgba(236, 236, 236, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    flex: 1;
    margin: 5px;
    min-width: 200px;
    flex-direction: row;
    background-color: #fff;
    height: 700px;
  }
  @media only screen and (max-width: 800px) {
    .content-img img {
      width: 20%;
      height: 80%;
      float: left;
    }
    .Cname {
      transform: scale(0.8);
      width: 100%;
      margin-bottom: 200px;
    }
    .Hwrapper {
      height: 300px;
    }
    .content-img {
      height: 400px !important;
    }
    .container {
      padding-top: 400px;
      flex-wrap: wrap;
      width: 100%;
    }
    .main-content {
      min-width: 60%;
      flex: 1 !important;
    }
    .side-content {
      min-width: 40%;
      display: hidden !important;
    }
  }
  