@import url(https://bootstrap/dist/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Background{ 
    top: 0;
    box-shadow: 0px 10px 40px #000020;
    background-color: #000000;
    height: 10%;
    width: 100%;
    z-index: 10;
    overflow: hidden;
    
}

.Logo{
    position: relative;
    float: left;
   height: 50px;
  top:10px;
  left:20px;
} 

#logo-mini{
  height:40px;
  position: absolute;
left:10px;
top:5px;
  
  
}
.nav-item{
   margin-right: 30px;
   float:left; 
   margin-top: 10px; 
   font-size: 20px;
   list-style-type:none;
   margin-left:5px;
   padding-bottom: 10px;
}
.nav-link{
        color: white;
        text-decoration: none;
}
.logIn{
  float:right;
  margin-top:30px;
  margin-right:20px;
  color: #fff !important;

}


.btn-secondary{
  background-color: #011118 !important;
 }

.list{
margin-left: 7%;
  
}
.nav-item-login{
  float:right;
}
.Burger-icon{
  background:transparent;
  color:#fff;
  border:none;
  position: absolute;
  right:5px;
  outline: none;
}

#Background-mini{
height: 50px;
}
.BackGround-img-burger{
    display: none;
}

.burgerbox{
    background:transparent;
   float: right;
   z-index: 111111;
   position: relative;

}    

.burgerList{

    display: inline-block;
    -webkit-margin-after: 0px;
            margin-block-end: 0px;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
    padding: 0px;

}

.burgerListHidden{
  display:block;
  background-image:  linear-gradient(to right, rgb(8, 7, 1), #011118);
  box-shadow: 0px 10px 40px black;
  position: absolute;
}

.burger-link{
    text-decoration: none;
    color: white;
    font-size: 25px;  
}
.burger-item{
    list-style-type:none;
    line-height: 30px;
}
.burger-item:hover{
    cursor: pointer;
    background-color: rgba(1,17,24,1);
}
.burger-link:active{
    padding-left:30px;
    padding-right:30px;
   
    background-color: #fff;
    color:black;
}

.table{
    margin:0 0 0 0;
}
.Burger-icon{
    background:transparent;
    color:#fff;
    border:none;
    position: absolute;
    right:5px;
    outline: none;
    height:50px;
    width: 50px;

  }

body{
    background-color: #b8b8c0 !important; 
  
}

.Header-img{
    position: relative;
    width: 100%;
    height: auto;
    box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);

}
.fus{
    position: relative;
    margin-top: 100px;
}
.main{
   position: relative ;
   padding: 300 px;
    Width: 750 px;
    height: 1000 px;
    background-color: none;
    z-index: 11;
    text-align: center ;
}
 .text{

   
  margin-right: 10%;
  margin-left: 10%;
 }
.btn:hover{
    background-color: white !important;
    color: #000008 !important
}
.btn{
    border: 2px solid !important;
    position: relative;
    display:inline-block;
    border-color: #fff  !important;
    background-color: #000010 !important;
    margin-bottom: 10 px;
}


.btn-secondary{
 background-color: #000010 !important;
}
.text{
   color: rgb(32, 31, 31) !important;
   background-color: none !important;
    text-align: center;
}

#edit{
    position: absolute;
    right: 10px;
    background-color: transparent;
    border:none;
    color:white;
    z-index: 10;
    
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
.Footer{
    background-color: black;
    width: 100%;
    height:200px;
    margin-top:30px;
    box-shadow: 0px 30px;
    box-shadow: -2px -14px 66px -5px rgba(13,9,46,1);
    overflow: hidden;
}

.Left-links-parent{
    position: absolute;
margin-left: 10%;
margin-top:10px;
}

.Left-links-header{
 color:white;
}


.Left-links{
   position: absolute;
   right:10px;
}

.Right-links-parent{
margin-top: 10px;

}

.Right-links-header{
    position: absolute;
    right:10px;
    margin-right: 10%;
    color:white;

}


.Right-links{
    position: absolute;
    right: 45px;
    float: right;
    margin-top: 40px;
    margin-right: 10%;

    
}

.Copy-right{
    
    margin-top:160px;
    display: block;
    text-align: center;
}


.Sign-in-login-parent{
    width: 100%;
    display:flex;
    flex-direction: row-reverse;
    justify-content : space-around;
    margin: 0;
    padding: 0;
    flex-wrap: wrap-reverse;
    margin-top: 80px;
    margin-bottom:100px;
}
 @media screen and (max-width:1900px){
     .Sign-in-Form{
         width:40%;
    
     }
     .Login-Form{
        width:40%;
     }
 }
 @media screen and (max-width:1000px){
    .Sign-in-Form{
        width:90%;
    }
    .Login-Form{
       width:90%;
    }
}

.Sign-in-Form{

margin:30px;
box-sizing: border-box;
}

.Sign-in-header{
    
color:white;


}


.Login-Form{
    margin:30px;
  
   box-sizing: border-box;
}

.Log-in-header{
    color:white;
  
}



.form-group{
    color:white;
  
}
.form-control{
   
    border-color: white;
    border: 3px solid white;
    background-color: #00000F;
    color:white;
    font-weight: bold;
}


.Cname {
  z-index: 100;
  display: flex;
  position: absolute;
  bottom: 0;
  margin-bottom: 70px;
  justify-content: center;
  align-items: center;
  background-color: #0066b3 !important;
  height: 100px;
  width: 300px;
  opacity: 0.7;
}
.Cname h1 {
  color: white;
  opacity: 1 !important;
}
.content-img {
  z-index: 0;
  top: 70px;
  width: 500%;
  height: 100% !important;
  margin: 0;
  left: 0;
  position: absolute !important;
  -webkit-animation: slider 20s infinite;
          animation: slider 20s infinite;
}
@-webkit-keyframes slider {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}
@keyframes slider {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}
.content-img img {
  width: 20%;
  height: auto;
  float: left;
}

.Hwrapper {
  overflow: hidden;
  position: absolute !important;
  width: 100%;
  height: 550px !important;
}
.container {
  flex-wrap: wrap !important;
  display: flex;
  padding-top: 500px;
  width: 100%;
  z-index: 1001;
}
.main-content {
  flex: 2 1;
  justify-content: space-around !important;
  flex-direction: row;
  min-width: 500px;
  margin: 5px;
  background-color: #fff;
  z-index: 1001;
  background: linear-gradient(
    0deg,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.side-content {
  z-index: 1001;
  min-width: 200px;
  background: linear-gradient(
    0deg,
    rgba(236, 236, 236, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  flex: 1 1;
  margin: 5px;
  min-width: 200px;
  flex-direction: row;
  background-color: #fff;
  height: 500px;
}
@media only screen and (max-width: 800px) {
  .content-img img {
    width: 20%;
    height: 80%;
    float: left;
  }
  .Cname {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    width: 100%;
    margin-bottom: 200px;
  }
  .Hwrapper {
    height: 300px;
  }
  .content-img {
    height: 400px !important;
  }
  .container {
    padding-top: 400px;
    flex-wrap: wrap;
    width: 100%;
  }
  .main-content {
    min-width: 60%;
    flex: 1 1 !important;
  }
  .side-content {
    min-width: 40%;
    display: hidden !important;
  }
}

.Cname {
    z-index: 100;
    display: flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 70px;
    justify-content: center;
    align-items: center;
    background-color: #0066b3 !important;
    height: 100px;
    width: 300px;
    opacity: 0.7;
  }
  .Cname h1 {
    color: white;
    opacity: 1 !important;
  }
  .content-img {
    z-index: 0;
    top: 70px;
    width: 500%;
    height: 100% !important;
    margin: 0;
    left: 0;
    position: absolute !important;
    -webkit-animation: slider 20s infinite;
            animation: slider 20s infinite;
  }
  @-webkit-keyframes slider {
    0% {
      left: 0;
    }
    20% {
      left: 0;
    }
    25% {
      left: -100%;
    }
    45% {
      left: -100%;
    }
    50% {
      left: -200%;
    }
    70% {
      left: -200%;
    }
    75% {
      left: -300%;
    }
    95% {
      left: -300%;
    }
    100% {
      left: -400%;
    }
  }
  @keyframes slider {
    0% {
      left: 0;
    }
    20% {
      left: 0;
    }
    25% {
      left: -100%;
    }
    45% {
      left: -100%;
    }
    50% {
      left: -200%;
    }
    70% {
      left: -200%;
    }
    75% {
      left: -300%;
    }
    95% {
      left: -300%;
    }
    100% {
      left: -400%;
    }
  }
  .content-img img {
    width: 20%;
    height: auto;
    float: left;
  }
  
  .Hwrapper {
    overflow: hidden;
    position: absolute !important;
    width: 100%;
    height: 550px !important;
  }
  .container {
    flex-wrap: wrap !important;
    display: flex;
    padding-top: 500px;
    width: 100%;
    z-index: 1001;
  }
  .main-content {
    flex: 2 1;
    justify-content: space-around !important;
    flex-direction: row;
    min-width: 500px;
    margin: 5px;
    background-color: #fff;
    z-index: 1001;
    background: linear-gradient(
      0deg,
      rgba(236, 236, 236, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .side-content {
    z-index: 1001;
    min-width: 200px;
    background: linear-gradient(
      0deg,
      rgba(236, 236, 236, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    flex: 1 1;
    margin: 5px;
    min-width: 200px;
    flex-direction: row;
    background-color: #fff;
    height: 700px;
  }
  @media only screen and (max-width: 800px) {
    .content-img img {
      width: 20%;
      height: 80%;
      float: left;
    }
    .Cname {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      width: 100%;
      margin-bottom: 200px;
    }
    .Hwrapper {
      height: 300px;
    }
    .content-img {
      height: 400px !important;
    }
    .container {
      padding-top: 400px;
      flex-wrap: wrap;
      width: 100%;
    }
    .main-content {
      min-width: 60%;
      flex: 1 1 !important;
    }
    .side-content {
      min-width: 40%;
      display: hidden !important;
    }
  }
  
.Header-img{
    position: relative;
    width: 250px;
    height: 200px;
    box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);

}

.MainContainer{
    text-align: center;
}
.MainContainer{
    padding: 10px;
}
.fb-img {
  height: 45px;
  width: auto;
  margin-right: 8px !important;
}
.ig-img {
  height: 40px;
  width: auto;
}
@media only screen and (max-width: 700px) {
  .contact-wrapper {
    font-size: 10px;
  }
}

