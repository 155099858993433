body{
    background-color: #b8b8c0 !important; 
  
}

.Header-img{
    position: relative;
    width: 100%;
    height: auto;
    -webkit-box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);
    -moz-box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);
    box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);

}
.fus{
    position: relative;
    margin-top: 100px;
}
.main{
   position: relative ;
   padding: 300 px;
    Width: 750 px;
    height: 1000 px;
    background-color: none;
    z-index: 11;
    text-align: center ;
}
 .text{

   
  margin-right: 10%;
  margin-left: 10%;
 }
.btn:hover{
    background-color: white !important;
    color: #000008 !important
}
.btn{
    border: 2px solid !important;
    position: relative;
    display:inline-block;
    border-color: #fff  !important;
    background-color: #000010 !important;
    margin-bottom: 10 px;
}


.btn-secondary{
 background-color: #000010 !important;
}
.text{
   color: rgb(32, 31, 31) !important;
   background-color: none !important;
    text-align: center;
}

#edit{
    position: absolute;
    right: 10px;
    background-color: transparent;
    border:none;
    color:white;
    z-index: 10;
    
}
