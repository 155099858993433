.Header-img{
    position: relative;
    width: 250px;
    height: 200px;
    -webkit-box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);
    -moz-box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);
    box-shadow: -2px 14px 66px -5px rgba(13,9,46,1);

}

.MainContainer{
    text-align: center;
}