.Sign-in-login-parent{
    width: 100%;
    display:flex;
    flex-direction: row-reverse;
    justify-content : space-around;
    margin: 0;
    padding: 0;
    flex-wrap: wrap-reverse;
    margin-top: 80px;
    margin-bottom:100px;
}
 @media screen and (max-width:1900px){
     .Sign-in-Form{
         width:40%;
    
     }
     .Login-Form{
        width:40%;
     }
 }
 @media screen and (max-width:1000px){
    .Sign-in-Form{
        width:90%;
    }
    .Login-Form{
       width:90%;
    }
}

.Sign-in-Form{

margin:30px;
box-sizing: border-box;
}

.Sign-in-header{
    
color:white;


}


.Login-Form{
    margin:30px;
  
   box-sizing: border-box;
}

.Log-in-header{
    color:white;
  
}



.form-group{
    color:white;
  
}
.form-control{
   
    border-color: white;
    border: 3px solid white;
    background-color: #00000F;
    color:white;
    font-weight: bold;
}
