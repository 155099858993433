.Footer{
    background-color: black;
    width: 100%;
    height:200px;
    margin-top:30px;
    box-shadow: 0px 30px;
    -webkit-box-shadow: -2px -14px 66px -5px rgba(13,9,46,1);
    -moz-box-shadow: -2px -14px 66px -5px rgba(13,9,46,1);
    box-shadow: -2px -14px 66px -5px rgba(13,9,46,1);
    overflow: hidden;
}

.Left-links-parent{
    position: absolute;
margin-left: 10%;
margin-top:10px;
}

.Left-links-header{
 color:white;
}


.Left-links{
   position: absolute;
   right:10px;
}

.Right-links-parent{
margin-top: 10px;

}

.Right-links-header{
    position: absolute;
    right:10px;
    margin-right: 10%;
    color:white;

}


.Right-links{
    position: absolute;
    right: 45px;
    float: right;
    margin-top: 40px;
    margin-right: 10%;

    
}

.Copy-right{
    
    margin-top:160px;
    display: block;
    text-align: center;
}

