.BackGround-img-burger{
    display: none;
}

.burgerbox{
    background:transparent;
   float: right;
   z-index: 111111;
   position: relative;

}    

.burgerList{

    display: inline-block;
    margin-block-end: 0px;
    margin-block-start: 0px;
    padding: 0px;

}

.burgerListHidden{
  display:block;
  background-image:  linear-gradient(to right, rgb(8, 7, 1), #011118);
  box-shadow: 0px 10px 40px black;
  position: absolute;
}

.burger-link{
    text-decoration: none;
    color: white;
    font-size: 25px;  
}
.burger-item{
    list-style-type:none;
    line-height: 30px;
}
.burger-item:hover{
    cursor: pointer;
    background-color: rgba(1,17,24,1);
}
.burger-link:active{
    padding-left:30px;
    padding-right:30px;
   
    background-color: #fff;
    color:black;
}

.table{
    margin:0 0 0 0;
}
.Burger-icon{
    background:transparent;
    color:#fff;
    border:none;
    position: absolute;
    right:5px;
    outline: none;
    height:50px;
    width: 50px;

  }