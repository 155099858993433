.fb-img {
  height: 45px;
  width: auto;
  margin-right: 8px !important;
}
.ig-img {
  height: 40px;
  width: auto;
}
@media only screen and (max-width: 700px) {
  .contact-wrapper {
    font-size: 10px;
  }
}
